body {
  margin: 0;
  padding: 0;
  font-family: Segoe UI, Segoe UI Web (West European), Segoe, -apple-system,
    BlinkMacSystemFont, Roboto, Helvetica Neue, Tahoma, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

.greco-header__app-launcher {
  display: none;
}
.greco-header__tick {
  display: none;
}

.app-wrapper--panelIsOpen {
  width: calc(100% - 340px);
}
.app-wrapper {
  position: absolute;
  overflow: auto;
  top: 50px;
  left: 0;
  /* bottom: 0; */
  right: 0;

  #clientsSearch,
  #insurersSearch {
    width: 300px;
  }
  #clientsSearchWide,
  #insurersSearchWide {
    width: 450px;
  }
  /* background-image: url(./assets/GrECo_Logo_Erste.jpg);
  background-size: 6%;
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;  */
}
.tabTable tbody tr {
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  border-collapse: collapse;
}

[data-icon-name="CircleFill"] {
  margin-top: 3px;
}

.ms-DatePicker-goToday {
  right: 14px;
}
.Toastify__toast-container {
  z-index: 123123123123123 !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #888888; /* Change the thumb color */
  border-radius: 10px; /* Add rounded corners to the thumb */
}

/* Add a hover effect */
::-webkit-scrollbar-thumb:hover {
  background-color: #aaaaaa; /* Change the thumb color on hover */
}

.react-split__sash {
  border-top: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.label-value-container {
  max-width: 1360px; /* Adjust the container width as per your design */
  margin: 0 auto; /* Center the container horizontally */
  padding: 20px 20px 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.label-value-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.label-value-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.label {
  font-weight: bold;
  flex: 1;
  text-align: right;
  margin-right: 10px;
}

.value {
  flex: 4;
}

.redcolor {
  color: red;
}
.rg-sumheader-cell {
  border-left-width: 0px !important;
  border-right-width: 0px !important;
  border-top-width: 0px !important;
}

.rg-customcheckmark-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cl-dialog-container {
  display: flex;
  height: calc(100vh - 350px);
  width: calc(100vw - 350px);
}

.cl-dialog-sidebar {
  flex: 0 0 400px;
  background-color: #f7f7f7;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.cl-dialog-item {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #e6e6e6;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.cl-dialog-item:hover {
  background-color: #d4d4d4;
}

.cl-dialog-content {
  flex: 1;
  padding: 0px 0px 0px 20px;
  /* background-color: #fff; */
  display: flex;
  flex-direction: column; /* This ensures the sections are stacked vertically */
  gap: 10px; /* Provides spacing between sections */
}

.cl-dialog-section {
  padding: 20px;
  background-color: #e9ecef;
  border-radius: 4px;
}

.gos-link {
  text-decoration: none;
  cursor: pointer;
}

.gos-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.narrow-datepicker input {
  width: 80px;
}
